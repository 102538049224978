




































































































































































































































// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';

// --- Models ---

// --- Services ---
import AppService from '@/services/app';
import AuthService from '@/services/auth';
import PortalService from '@/services/portal';

// --- Third Party imports ---
import crypto from 'crypto-js';

@Component
export default class RegisterPage extends Vue {
  user = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    portalTypeId: 0,
  };
  tAndC: boolean = false;

  valid: boolean = false;  
  clicked: boolean = false;
  showPassword: boolean = false;  

  rules = {
    required: (value: string) => !!value || '',
    min: (v: string) => v.length >= 8 || 'At least 8 characters',
    name: (value: string) => /^(?=.*[a-zA-Z- ])[a-zA-Z- ]+$/.test(value) || 'Remove invalid characters',
    email: (value: string) => /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(value) || 'E-mail must be valid',
  };

  showInfoDialog: boolean = false;

  async beforeMount() {
    await PortalService.listTypes(false);
  }

  getCopyRight() {
    return process.env.VUE_APP_COPYRIGHT;
  }

  async register() {  
    try {
      this.clicked = true;

      const response = await AuthService.register({
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        password: crypto.SHA256(this.user.password).toString().toUpperCase(),
        portalTypeId: this.user.portalTypeId,
      });

      if (response && response.data) {
        if (response.data.result === 'false') {
          AppService.errorHandler(response.data.message);
        } else {
          AppService.successHandlerWithCustomTimeout(response.data.message, 5000);
          this.goToLogin();
        }
      } else {
        // response is undefined or has no data field - SHOULD NEVER HAPPEN!
        throw new Error('response: ' + JSON.stringify(response));
      }

    } catch (error) {
      if (error.response) {
        AppService.errorHandler(error.response.statusText);
      } else {
        AppService.logSupportDebug('Register.vue - register - 304 - ' + error);
        AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
      }

    } finally {
      this.clicked = false;
    }
  }

  goToLogin() {
    this.$router.push({ name: 'Login'});
  }
}
