/**
 *   This is a service for a specific store module which uses the api.ts Axios instance as a base.
 *   We separate our files for easy managment and readibility.
 *   We export this as a service to be used in other areas of the application.
 */

import Api from '@/services/api';
import Store from '@/store/index';
import AppService from '@/services/app';

export default {

  resetTypes() {
    // reset store to empty
    Store.dispatch('portals/setPortalTypes', {
      portalTypes: [],
    });
  },

  async listTypes(refreshFromAPI: boolean) {
    // check if we need to force an API refresh or have already fetched this data and it is in the Store
    if (refreshFromAPI || Store.getters['portals/portalTypes'].length < 1) {
      try {
        const response = await Api().get('/portal/list_types');
        
        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            this.resetTypes();
            return response.data.message;
          } else {
            Store.dispatch('portals/setPortalTypes', {
              portalTypes: response.data,
            });
            return '';
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        this.resetTypes();
        if (error.response) {
          return error.response.statusText;
        } else {
          AppService.logSupportDebug('portal.ts - listTypes - 46 - ' + error);
          return Store.getters['app/messages'].couldNotConnect;
        }
      } 
    } else {
      // this data is ready to access in the Store
      return '';
    }
  },
  
};
